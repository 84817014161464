<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Catálogos</h1>
    </div>
    <br />

    <DataGrid :config="dataGridConfig" :data="catalogos" :select="seleccionar" @actions="dataGridActions">
      <div class="row">
        <div class="col-sm-2"><button class="btn warning-btn" @click="obtener_catalogos">Recargar</button></div>
        <div class="col-sm-2"><button class="btn secondary-btn" @click="ver_catalogo">Ver catálogo</button></div>
        <div v-if="this.$auth.can('tesoreria', 'catalogos_crear')" class="col-sm-2"><button class="btn complementary-btn" @click="nuevo_catalogo=true">Nuevo catálogo</button></div>
      </div>
    </DataGrid>

    <NuevoCatalogo v-if="nuevo_catalogo" :data="catalogo" @close="cerrar_catalogo" @update="cerrar_update_catalogo" />
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2'
  import NuevoCatalogo from './Catalogo/Nuevo'

  import api from '@/apps/tesoreria/api/catalogos'

  export default {
    components: {
      DataGrid, NuevoCatalogo
    }
    ,data: function() {
      return {
        dataGridConfig: {
          name: 'facturas'
          ,cols: {
            catalogo: 'Catálogo'
            ,codigo: 'Código'
            ,estatus: 'Estatus'
            ,created_at: 'Fecha creación'
          }
          ,paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          }
          ,mutators: {
            estatus: function(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,seleccionar: false
        ,seleccionadas: []
        ,catalogos: []
        ,nuevo_catalogo: false
        ,catalogo: {
          catalogo: null
          ,codigo: null
          ,estatus: 1
          ,valores: []
        }
      }
    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        this.$log.info('tipo', tipo);
        if (tipo == 'options') {
          this.options = data;
          this.obtener_catalogos();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,obtener_catalogos: async function() {
        try {
          let res = (await api.obtener_catalogos(this.options)).data;

          this.catalogos = res.data;
          this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
          this.dataGridConfig.paginator.total_registros = res.data.total;
          this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,ver_catalogo: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar el catálogo a visualizar','error','alert');

        this.catalogo = this.seleccionadas[0];

        this.nuevo_catalogo = true;
      }
      ,cerrar_catalogo: function() {
        this.nuevo_catalogo = false;
        this.seleccionar = false;

        this.catalogo = {
          catalogo: null
          ,codigo: null
          ,estatus: 1
          ,valores: []
        }
      }
      ,cerrar_update_catalogo: function() {
        this.cerrar_catalogo();
        this.obtener_catalogos();
      }
    }
  }
</script>